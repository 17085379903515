<template>
  <div>
    <!-------------------------------------------TOOLBAR------------------------------------------->
    <toolbar
      ref="toolbar"
      :items="datatableItems"
      :approve="approveBtn"
      :delete="deleteBtn"
      @search="search"
      @clear-search="clearSearch"
      @scanner="openQrScanner"
      @insert="openInsertDialog"
      @approved="approveMethod"
      @deleted="deleteMethod"
    />
    <!-----------------------------------------QR SCANNER------------------------------------------>
    <qr-scanner
      ref="qrScanner"
      @edit="openEditDialog"
      @select-main-part="selectMainPart"
      @changed-location="getParts"
      @close="closeQrScanner"
    />
    <!--------------------------------------INSERT EMPTY PARTS------------------------------------->
    <insert-empty-dialog
      ref="insertEmptyDialog"
      @inserted="getParts"
    />
    <!----------------------------------------INSERTED PARTS--------------------------------------->
    <!--TOOLBAR-->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
    >
      <v-toolbar-title
        class="pointer"
        @click="showInserted = !showInserted"
      >
        {{ $t('parts.insert_parts.inserted_parts') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-select
          v-if="showInserted"
          v-model="selectedInsertItems"
          :items="insertItems"
          class="hidden-sm-and-down"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          color="red darken-2"
          background-color="grey darken-4"
          label="Select part's status"
        />
        <v-btn
          flat
          icon
          @click="showInserted = !showInserted"
        >
          <v-icon v-if="showInserted">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!-- SELECTION 'SM AND DOWN' -->
    <v-layout
      v-if="showInserted"
      class="grey darken-4"
      row
      wrap
      justify-center
    >
      <v-flex
        xs12
        sm10
      >
        <v-select
          v-model="selectedInsertItems"
          :items="insertItems"
          class="hidden-md-and-up"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          color="red darken-2"
          background-color="grey darken-4"
          label="Select part's status"
        />
      </v-flex>
    </v-layout>

    <!-- Components inside div are rendered only when Inserted Parts toolbar is expanded.-->
    <div v-if="showInserted">
      <!--DATATABLE-->
      <datatable
        :search="searchObject"
        :selected_items="selectedInsertItems"
        @activate-snackbar="activateSnackbar"
        @approve="approve"
        @qr="openQrDialog"
        @images="openImagesCarousel"
        @items="getDatatableItems"
        @edit="openEditDialog"
        @delete="getParts"
        @view-car="openCarDialog"
        @actions="openActionsDialog"
        @show-notes="openNotesDialog"
      />
      <!--QR DIALOG-->
      <qr-dialog ref="qrDialog" />
      <!--CAROUSEL-->
      <img-carousel ref="imgCarousel" />
      <!--NOTES DIALOG-->
      <notes-dialog ref="notesDialog" />
    </div>

    <!------------------------------------------EDIT PART------------------------------------------>
    <!--EDIT DIALOG-->
    <edit-dialog
      ref="editDialog"
      :hide="hideEditDialog"
      @new-shipment="openShipmentDialog"
      @scan-shipment="openShipmentScanner"
      @open-img-dialog="openImgDialog"
      @open-img-editor="openImgEditor"
      @scan-main-part="scanMainPart"
      @close="getParts"
    />
    <!-- ACTIONS DIALOG -->
    <actions-dialog
      ref="actionsDialog"
      @add-client="openClientsDialog"
      @close="getParts"
    />
    <clients-dialog
      ref="clientsDialog"
      @saved="getClients"
      @closed="showActionsDialog"
    />
    <!--SHIPMENT DIALOG-->
    <shipment-dialog
      ref="shipmentDialog"
      @close="closeShipmentDialog"
      @save="saveShipment"
    />
    <!--SHIPMENT SCANNER-->
    <shipment-scanner
      ref="shipmentScanner"
      @select-shipment="selectShipment"
      @close="closeShipmentScanner"
    />
    <!--IMAGE DIALOG-->
    <img-dialog
      ref="imgDialog"
      @close="closeImgDialog"
    />
    <img-editor
      ref="imgEditor"
      @save="editImage"
      @close="closeImgDialog"
    />
    <!-----------------------------------------EMPTY PARTS----------------------------------------->
    <!--TOOLBAR-->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
    >
      <v-toolbar-title
        class="pointer"
        @click="showEmpty = !showEmpty"
      >
        {{ $t('parts.insert_parts.empty_parts.empty_parts') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-select
          v-if="showEmpty"
          v-model="groupEmptyParts"
          :items="groupItemsEmptyParts"
          class="hidden-xs-only"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          color="red darken-2"
          background-color="grey darken-4"
        />

        <v-btn
          flat
          icon
          @click="showEmpty = !showEmpty"
        >
          <v-icon v-if="showEmpty">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!--SELECTION 'XS'-->
    <v-layout
      v-if="showEmpty"
      class="grey darken-4"
      row
      wrap
      justify-center
    >
      <v-flex
        xs12
      >
        <v-select
          v-if="showEmpty"
          v-model="groupEmptyParts"
          :items="groupItemsEmptyParts"
          class="hidden-sm-and-up"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          color="red darken-2"
          background-color="grey darken-4"
        />
      </v-flex>
    </v-layout>

    <!--Components inside div are rendered only when Empty Parts toolbar is expanded.-->
    <div v-if="showEmpty">
      <!--OPEN ALL QR CODES BUTTON-->
      <show-qr-codes @parts="openEmptyQrDialog" />
      <!--EMPTY PARTS COMPONENT-->
      <empty-parts
        ref="emptyParts"
        :groupby="groupEmptyParts"
        @qr="openEmptyQrDialog"
      />
      <!-- EMPTY PARTS QR DIALOG -->
      <empty-qr-dialog ref="emptyQrDialog" />
    </div>
    <!------------------------------------------SNACKBAR------------------------------------------->
    <v-snackbar
      v-model="snackbar.value"
      color="orange"
    >
      {{ snackbar.text }}
      <v-btn
        flat
        dark
        @click="snackbar.value = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <!--------------------------------------------------------------------------------------------->
    <car-dialog ref="carDialog" />
    <!--------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import Toolbar from '@/components/InsertParts/toolbar.vue';
import Datatable from '@/components/InsertParts/datatable.vue';
import EditDialog from '@/components/InsertParts/editDialog.vue';
import QrScanner from '@/components/InsertParts/qrScanner.vue';
import ShipmentScanner from '@/components/InsertParts/shipmentScanner.vue';
import InsertEmptyDialog from '@/components/InsertParts/insertEmpty.vue';
import EmptyParts from '@/components/InsertParts/empty.vue';
import EmptyQrDialog from '@/components/InsertParts/qrCodesDialog.vue';
import ShowAllQrCodes from '@/components/InsertParts/showAllQrCodes.vue';
import ActionsDialog from '@/components/InsertParts/actionsDialog.vue';
import ClientsDialog from '@/components/Clients/editDialog.vue';

import ShipmentDialog from '@/components/Shipments/dialog.vue';

import QrDialog from '@/components/qrDialog.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import ImgDialog from '@/components/imgDialog.vue';
import ImgEditor from '@/components/imgEditor.vue';
import NotesDialog from '@/components/notesDialog.vue';

import CarDialog from '@/components/carDialog.vue';

import putRequest from '../api/putRequest';
import deleteRequest from '../api/deleteRequest';

export default {
  components: {
    toolbar: Toolbar,
    datatable: Datatable,
    'qr-dialog': QrDialog,
    'empty-qr-dialog': EmptyQrDialog,
    'img-carousel': ImgCarousel,
    'qr-scanner': QrScanner,
    'shipment-scanner': ShipmentScanner,
    'edit-dialog': EditDialog,
    'insert-empty-dialog': InsertEmptyDialog,
    'empty-parts': EmptyParts,
    'img-dialog': ImgDialog,
    'img-editor': ImgEditor,
    'notes-dialog': NotesDialog,
    'show-qr-codes': ShowAllQrCodes,
    'actions-dialog': ActionsDialog,
    'clients-dialog': ClientsDialog,

    'shipment-dialog': ShipmentDialog,

    'car-dialog': CarDialog,
  },
  data: () => ({
    approveBtn: false,
    deleteBtn: false,
    approveItems: [],
    datatableItems: [],
    searchObject: {
      type: null,
      data: {},
    },
    hideEditDialog: false,
    showInserted: true,
    showEmpty: false,
    snackbar: {
      value: false,
      text: '',
    },
    insertItems: [
      { text: 'Available', value: 1 },
      { text: 'Waiting Approval', value: 2 },
      { text: 'Review Needed', value: 3 },
      { text: 'Refused', value: 4 },
      { text: 'Approved', value: 5 }],
    selectedInsertItems: [1, 3, 4, 5],
    groupItemsEmptyParts: [
      { text: 'Group by Date', value: 'date' },
      // { text: 'Group by Shipment', value: 'shipment' }, // disabled
    ],
    groupEmptyParts: { text: 'Date', value: 'date' },
  }),
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.insertItems[0].text = this.$i18n.t('parts.status.available');
      this.insertItems[1].text = this.$i18n.t('parts.status.waiting_approval');
      this.insertItems[2].text = this.$i18n.t('parts.status.review_needed');
      this.insertItems[3].text = this.$i18n.t('parts.status.refused');
      this.insertItems[4].text = this.$i18n.t('parts.status.approved');
      this.groupItemsEmptyParts[0].text = this.$i18n.t('parts.insert_parts.empty_parts.group_by_date');
      this.groupItemsEmptyParts[1].text = this.$i18n.t('parts.insert_parts.empty_parts.group_by_shipment');
    },
    getParts() { // Fired when saving or deleting part.
      // Get Inserted Parts.
      if (this.searchObject.type === null) {
        this.clearSearch();
      } else if (this.searchObject.type === 'regularSearch') {
        this.search(this.searchObject.data);
      }
      // Get Empty Parts.
      if (this.showEmpty) {
        const { emptyParts } = this.$refs;
        emptyParts.getEmptyParts();
      }
    },
    /* SEARCH ------------------------------------------------------------------------------------*/
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    /* INSERT PARTS ------------------------------------------------------------------------------*/
    openInsertDialog() {
      const { insertEmptyDialog } = this.$refs;
      insertEmptyDialog.open();
    },
    /* APROVE PARTS ------------------------------------------------------------------------------*/
    approve(data) {
      this.approveBtn = data.value;
      this.approveItems = data.items;
      this.deleteBtn = data.value;
    },
    approveMethod() {
      const { toolbar } = this.$refs;

      toolbar.startLoading();
      const parts = [];
      this.approveItems.forEach(item => parts.push({ id: item._id, kit_id: item.kit_id }));
      putRequest('/temporary-part/insert/approve', '', {
        parts,
        institution_id: this.$store.getters.institutionId,
        user_id: this.$store.getters.userId,
      })
        .then((response) => {
          console.log(response);
          toolbar.stopLoading();
          this.getParts();
        })
        .catch((error) => {
          console.log(error);
          toolbar.stopLoading();
        });
    },

    deleteMethod() {
      const { toolbar } = this.$refs;
      const ids = this.approveItems.map(x => x._id);
      console.log('deleted', ids.length);

      const counter = ids.length;
      // eslint-disable-next-line no-restricted-globals
      const c = confirm(this.$t('delete_alert2', { count: counter }));

      if (c === true) {
        // console.log("Confirmed deletion. Performing delete operation...");
        toolbar.startLoadingDelete();
        deleteRequest('/temporary-part/insert', `ids=${ids}&user_id=${this.$store.getters.userId}&institution_id=${this.$store.getters.institutionId}`) // eslint-disable-next-line max-len
          .then((response) => {
            console.log(response);
            toolbar.stopLoadingDelete();
            this.getParts();
          })
          .catch((error) => {
            console.log(error);
            toolbar.stopLoadingDelete();
          });
      } else {
        console.log('Deletion canceled by the user.');
      }
    },

    /* DATATABLE ITEMS ---------------------------------------------------------------------------*/
    getDatatableItems(data) {
      this.datatableItems = data;
    },
    /* QR DIALOG ---------------------------------------------------------------------------------*/
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    openEmptyQrDialog(data) {
      const { emptyQrDialog } = this.$refs;
      emptyQrDialog.open(data);
    },
    /* IMAGES CAROUSEL ---------------------------------------------------------------------------*/
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
      console.log(data);
    },
    /* EDIT DIALOG -------------------------------------------------------------------------------*/
    openEditDialog(data, kit) {
      const { editDialog } = this.$refs;
      editDialog.open(true, kit, data);
    },
    /* Actions Dialog --------------------------------------------------------------------------- */
    openActionsDialog(data) {
      const { actionsDialog } = this.$refs;
      actionsDialog.open(data);
    },
    /* Clients Dialog --------------------------------------------------------------------------- */
    openClientsDialog() {
      const { clientsDialog } = this.$refs;
      const { actionsDialog } = this.$refs;
      clientsDialog.open(false, null, this.$store.getters.institutionId);
      actionsDialog.hide();
    },
    getClients() {
      const { actionsDialog } = this.$refs;
      actionsDialog.getClients();
    },
    showActionsDialog() {
      const { actionsDialog } = this.$refs;
      actionsDialog.show();
    },
    /* KIT ---------------------------------------------------------------------------------------*/
    scanMainPart() {
      const { qrScanner } = this.$refs;
      this.hideEditDialog = true;
      qrScanner.open({ mode: 'kit' });
    },
    selectMainPart(part) {
      const { editDialog } = this.$refs;
      editDialog.setMainPart(part);
      this.hideEditDialog = false;
    },
    closeQrScanner() {
      this.hideEditDialog = false;
    },
    /* IMAGE DIALOG ----------------------------------------------------------------------------- */
    openImgDialog(image) {
      const { imgDialog } = this.$refs;
      imgDialog.open(image);
      this.hideEditDialog = true;
    },
    openImgEditor(image) {
      const { imgEditor } = this.$refs;
      imgEditor.open(image);
      this.hideEditDialog = true;
    },
    editImage(image) {
      const { editDialog } = this.$refs;
      editDialog.setEditedImage(image);
      this.hideEditDialog = false;
    },
    closeImgDialog() {
      this.hideEditDialog = false;
    },
    /* SHIPMENT DIALOG -------------------------------------------------------------------------- */
    openShipmentDialog() {
      const { shipmentDialog } = this.$refs;
      const { editDialog } = this.$refs;
      shipmentDialog.open(false, null);
      editDialog.hideEditDialog(true);
    },
    closeShipmentDialog() {
      const { editDialog } = this.$refs;
      editDialog.hideEditDialog(false);
    },
    saveShipment() {
      const { editDialog } = this.$refs;
      editDialog.getShipments();
    },
    /* SHIPMENT SCANNER ------------------------------------------------------------------------- */
    openShipmentScanner() {
      const { shipmentScanner } = this.$refs;
      const { editDialog } = this.$refs;
      shipmentScanner.open();
      editDialog.hideEditDialog(true);
    },
    closeShipmentScanner() {
      const { editDialog } = this.$refs;
      editDialog.hideEditDialog(false);
    },
    selectShipment(shipmentId) {
      const { editDialog } = this.$refs;
      editDialog.setShipment(shipmentId);
    },
    /* QR SCANNER ------------------------------------------------------------------------------- */
    openQrScanner() {
      const { qrScanner } = this.$refs;
      qrScanner.open({ mode: 'single' });
    },
    /* CAR DIALOG ------------------------------------------------------------------------------- */
    openCarDialog(data) {
      const { carDialog } = this.$refs;
      carDialog.open(data);
    },
    /* Notes dialog ----------------------------------------------------------------------------- */
    openNotesDialog(notes) {
      const { notesDialog } = this.$refs;
      notesDialog.open(notes);
    },
    /* SNACKBAR --------------------------------------------------------------------------------- */
    activateSnackbar(data) {
      this.snackbar = {
        value: true,
        text: data,
      };
    },
  },
};
</script>
