<template>
  <v-container
    fluid
    class="pa-0"
  >
    <toolbar
      :items="datatableItems"
      @search="search"
      @adv-search="advancedSearch"
      @clear-search="clearSearch"
    />
    <datatable
      :search="searchObject"
      @items="getDatatableItems"
      @details="openDetailsDialog"
      @images="openImagesCarousel"
      @price="openPriceDialog"
      @buyer-details="showBuyerDetails"
      @qr="openQrDialog"
      @actions="openActionsDialog"
    />
    <qr-dialog ref="qrDialog" />
    <img-carousel ref="imgCarousel" />
    <buyer-details ref="buyerDetails" />
    <price-dialog ref="priceDialog" />
    <actions-dialog
      ref="actionsDialog"
      @action-completed="getParts"
    />
  </v-container>
</template>

<script>
import Datatable from '@/components/OrderedParts/datatable.vue';
import Toolbar from '@/components/OrderedParts/toolbar.vue';
import ActionsDialog from '@/components/OrderedParts/actionsDialog.vue';

import BuyerDetails from '@/components/buyerDetails.vue';
import PriceDialog from '@/components/priceDialog.vue';
/* import DetailsDialog from '@/components/detailsDialog.vue'; */
import ImgCarousel from '@/components/imgCarousel.vue';
import QrDialog from '@/components/qrDialog.vue';

export default {
  components: {
    datatable: Datatable,
    toolbar: Toolbar,
    'buyer-details': BuyerDetails,
    /* 'details-dialog': DetailsDialog, */
    'actions-dialog': ActionsDialog,
    'price-dialog': PriceDialog,
    'img-carousel': ImgCarousel,
    'qr-dialog': QrDialog,
  },
  data: () => ({
    searchObject: {
      type: null,
      data: {},
    },
    datatableItems: [],
  }),
  mounted() {},
  methods: {
    getParts() {
      // Fired when action is complete.
      if (this.searchObject.type === null) {
        this.clearSearch();
      } else if (this.searchObject.type === 'regularSearch') {
        this.search(this.searchObject.data);
      } else if (this.searchObject.type === 'advancedSearch') {
        this.advancedSearch(this.searchObject.data);
      }
    },

    /* Search ----------------------------------------------------------------------------------- */
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    advancedSearch(data) {
      this.searchObject = {
        type: 'advancedSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    /* Datatable Items -------------------------------------------------------------------------- */
    getDatatableItems(data) {
      this.datatableItems = data;
    },
    /* Details Dialog --------------------------------------------------------------------------- */
    openDetailsDialog(data) {
      const { detailsDialog } = this.$refs;
      detailsDialog.open(data);
    },
    /* Img Carousel ----------------------------------------------------------------------------- */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
      console.log(data);
    },
    /* Price Dialog ----------------------------------------------------------------------------- */
    openPriceDialog(data) {
      console.log(data);
      const { priceDialog } = this.$refs;
      priceDialog.open(data);
    },
    /* Buyer Details ---------------------------------------------------------------------------- */
    showBuyerDetails(data) {
      const { buyerDetails } = this.$refs;
      buyerDetails.showBuyerDetails(data);
    },
    /* QR Dialog -------------------------------------------------------------------------------- */
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    /* Actions ---------------------------------------------------------------------------------- */
    openActionsDialog(data) {
      const { actionsDialog } = this.$refs;
      actionsDialog.open(data);
    },
  },
};
</script>
